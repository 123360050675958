.switcher-language {
    margin-left: 1rem;
    color: $gray-700;

    .switcher-label {
        margin-right: 0.2em;
    }

    .switcher-view {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            min-width: 24px;
        }

        span {
            text-decoration: underline;
        }
    }
}

.header-top-right {
    .icon-angle-down {
        margin-top: -0.125rem;
        margin-left: 0.125rem;
        @include svg-icon(0.875rem, 0.875rem, $icon-chevron-down, $primary);
        vertical-align: middle;
    }
}

.modal-storeswitcher {
    .modal-dialog {
        max-width: 800px;
        margin: 8rem auto
    }

    .modal-header {
        padding: 4rem;
        color: $primary;
        border: none;
        text-align: center;

        .modal-title {
            width: 100%;
        }

        .close {
            color: $primary;
            font-size: 3rem;
            opacity: 1;
        }
    }

    .modal-content {
        border: 2px solid $primary;
    }

    .store-list {
        width: 75%;
        margin: 0 auto;
        padding: 0;
        padding-bottom: 4rem;
        list-style: none;

        li {
            display: block;

            +li {
                margin-top: 1.5rem;
                margin-left: 0;
            }

            span {
                color: $primary;
                font-size: 1.2rem;
                font-weight: 300;
                vertical-align: middle;
            }

            img {
                width: 2rem;
                margin-right: 0.5rem;
                vertical-align: middle;
            }

            a {
                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        a {
            color: $body-color;
            text-decoration: none;
        }
    }
}

.language-mobile-switcher {
    display: none;
    padding: 1rem;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
        display: flex;
    }
}
