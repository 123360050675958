.checkout-index-index {
    .dvd-case {
        .field {
        }
        label.label {
            position: relative;
            top: auto;
            left: 0;
            cursor: pointer;
        }
    }
}
