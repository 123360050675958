.offcanvas,
.offcanvas-md,
.offcanvas-sm {
    .offcanvas-heading {
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1rem;

        .close {
            color: $white;
            font-size: 3rem;
            opacity: 1;
            float: none;
        }
    }
}

.offcanvas {
    .offcanvas-heading {
        display: flex;
    }
}

@include media-breakpoint-down(md) {
    .offcanvas-md {
        .offcanvas-heading {
            display: flex;
        }
    }
}

@include media-breakpoint-down(sm) {
    .offcanvas-sm {
        .offcanvas-heading {
            display: flex;
        }
    }
}

@include media-breakpoint-down(xs) {
    .offcanvas-xs {
        .offcanvas-heading {
            display: flex;
        }
    }
}
