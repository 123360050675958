.field.downloads {
    margin-left: 2rem;

    .label {
        color: $primary;
        font-weight: 700;

        &::after {
            display: none;
        }
    }

    .choice {
        .label {
            color: $gray-600;
            font-weight: 400;
        }

        .price-wrapper {
            color: $gray-600;
            font-size: $font-size-base;
            font-weight: 400;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        margin-left: 0;
        margin-top: 2rem;
    }
}

.page-product-downloadable {
    .product-add-to-links {
        .product-add-form {
            margin-bottom: 0;
        }
    }
}

.table-downloadable-products {
    td.col, th.col {
        width: auto;
    }
    th.title {
        width: 50%;
    }
}
