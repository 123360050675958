//
//  Base styles for Ajax Cart
//  _________________________

//
//  Common
//  --------------------------

// & when (@media-common = true) {
.hide {
    display: none;
}

.amcart-message-box,
.am-related-box {
    & {
        min-width: 320px;
    }

    &.required {
        // @include lib-css(color, $am-required__color);
        color: $am-required__color;
    }

    &.actions,
    .action.back.customization {
        // &:extend(.hide);
        @extend .hide;
    }

    &.price-box {
        margin: 0;
        margin-top: map-get($spacers, 1);
        margin-left: map-get($spacers, 2);
    }

    &.price-box p {
        margin: 0;
        padding: 0;
    }

    .text {
        // .lib-css(font-size, @am-text__font-size);
        display: block;
        margin-top: map-get($spacers, 2);
        font-weight: normal;

        &:first-of-type {
            margin-top: 0;
            display: inline;
        }
    }

    // Override magento styles
    .product-info-main {
        float: none;
        width: auto !important;
    }

    .related {
        margin: 0 auto;
        width: 320px;
    }

    .product-add-form {
        margin: 0 25px;
        text-align: left;
    }

    .product-item-actions {
        display: block;
    }

    .amcart-subtotal {
        font-size: 14px;
    }

    #amcart-count {
        margin-bottom: 0;
        font-size: 14px;
    }

    .stock.unavailable {
        margin-top: 10px;
    }
}

.amcart-message-box {
    & {
        margin: 40px auto 13px;
    }

    .product-options-bottom .actions,
    .box-tocart .actions {
        display: none;
    }
}

.am-related-box {
    & {
        margin: 20px auto 13px;
    }

    .am-title {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        color: $primary;
    }

    .am-product-image.product {
        position: relative;
        margin: 0 0 5px 0;
    }

    .products-crosssell .product-item {
        padding: 0;
    }
}

#bundleSummary {

    .title,
    .bundle-summary,
    .product-addto-links,
    .price-box {
        // &:extend(.hide);
        @extend .hide;
    }
}

#confirmOverlay {
    & {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        // .lib-vendor-prefix-display(flex);
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        // .lib-vendor-box-align(center);
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .15);
    }

    img {
        display: inline;
    }
}

#confirmBox {
    & {
        position: relative;
        height: auto;
        max-height: 88vh;
        max-width: 100%;
        text-align: center;
        z-index: 9999;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .25);
        border-radius: 9px;
        overflow-y: auto;
    }

    >p {
        text-shadow: 1px 1px 0 rgba(255, 255, 255, .6);
        line-height: normal;
        font-weight: normal;
        background: none;
        font-size: 16px;
    }

    >.cross {
        position: absolute;
        top: 5px;
        right: 10px;
        color: rgba(0, 0, 0, .25);
        font-size: 21px;
        z-index: 9;
    }

    >.cross:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, .45)
    }

    .am-actions-wrapper {

        >.towishlist,
        >.tocompare {
            // .lib-css(background, @am-actions-icon-__background-color no-repeat center);
            background: ($am-actions-icon-__background-color no-repeat center);
            background-size: 60%;
        }

        >.towishlist:before,
        >.tocompare:before {
            content: '';
        }

        >.towishlist {
            // .lib-css(background-image, @am-actions-icon-wishlist__background-image);
            background-image: $am-actions-icon-wishlist__background-image;
        }

        >.towishlist:hover {
            // .lib-css(background-image, @am-actions-icon-wishlist-hover__background-image);
            background-image: $am-actions-icon-wishlist-hover__background-image;

        }

        >.tocompare {
            // .lib-css(background-image, @am-actions-icon-compare__background-image);
            background-image: $am-actions-icon-compare__background-image;
        }

        >.tocompare:hover {
            // .lib-css(background-image, @am-actions-icon-compare-hover__background-image);
            background-image: $am-actions-icon-compare-hover__background-image;

        }
    }

    .product-item-name {
        margin: 0;
        margin-bottom: map-get($spacers, 2);
    }

    .am-slider {
        margin: 0;
        margin-top: map-get($spacers, 3);
    }

    .actions-secondary .action {
        // .lib-css(color, @am-actions-icon-mobile__color);
        color: $am-actions-icon-mobile__color;
    }

    .product-item .price-box {
        margin: 0;
        margin-bottom: map-get($spacers, 3);
    }

    .product-options-bottom .product-social-links {
        display: none;
    }

    /* rewrite magento styles*/
    .product-options-bottom .grouped .price-box .price-container .price,
    .product-options-bottom .grouped .price-box .price-container {
        font-size: $font-size-sm;
        line-height: 20px;
    }

    .product-options-bottom .box-tocart {
        margin-top: 1.25rem;
    }
}

.amcart-confirm-buttons {
    & {
        margin: 1.25rem auto;
    }

    .button {
        position: relative;
        display: inline-block;
        height: 3rem;
        // .lib-css(width, @am-button__width);
        width: $am-button__width;
        font-weight: $font-weight-base;
        font-size: $font-size-lg;
        line-height: 23px;
        border-radius: $border-radius-sm;
        cursor: pointer;
        box-shadow: 0 -2px 0 rgba(0, 0, 0, .15) inset;
        padding: 7px 10px;
    }

    .button:hover {
        opacity: .8;
    }

    .checkout {
        display: none;
        padding: 25px 0 8px 0;
        font-size: $font-size-base;
    }

    .button:last-child {
        margin-right: 0;
    }

    .am-btn-left {
        // .lib-css(margin-right, @am-button__margin);
        margin-right: map-get($spacers, 2);
    }

    button.am-btn-right,
    button.am-btn-left {
        border: 0;
    }

    .timer {
        display: none;
    }
}

.product-details {
    position: relative;
    bottom: 3px;
}

.am-cart-info {
    & {
        position: absolute;
        top: 50%;
        left: 50%;
        // .lib-css(transform, translate(-50%, -50%), 1);
        transform: translate(-50%, -50%);
        color: $black;
        font-weight: $font-weight-bold;
        text-align: center;
    }
}

.added-item {
    display: inline;
    font-weight: $font-weight-bold;
}

.am_price {
    font-weight: bold;
}

.mask {
    opacity: .4;
}

#am-a-count {
    font-weight: $font-weight-bold;
    color: $primary;
}

#am-a-count:visited {
    color: $primary;
}

.amcart-message-box .products {
    .product-item-name {
        margin: 0;
    }

    .product-item-actions {

        .secondary-addto-links,
        .actions-primary,
        .actions-secondary {
            text-align: center;
        }

        .actions-primary,
        .secondary-addto-links {
            display: block;
            float: none;
            margin: 5px auto;
        }

        .tocart {
            // .lib-css(background, @am-button-secondary__background);
            background: $am-button-secondary__background;
            box-shadow: 0 -2px 0 rgba(0, 0, 0, .15) inset;
            border-radius: 3px;
            border: 0;
        }
    }
}

.am-related-box .products {
    .product-item-name {
        margin: 0;
    }

    .product-item-actions {

        .secondary-addto-links,
        .actions-primary,
        .actions-secondary {
            text-align: center;
        }

    }

    .product-item-actions .actions-primary,
    .product-item-actions .secondary-addto-links {
        display: block;
        float: none;
        margin: map-get($spacers, 1) auto map-get($spacers, 2);
    }

    .product-item-actions .am-actions-wrapper {
        & {
            position: absolute;
            // .lib-css(top, -@am-actions-secondary__size);
            top: ($am-actions-secondary__size * -1);
            right: 0;
            margin: 0;
            display: none;
            width: 100%;
            transition: all .2s ease-in-out;
            z-index: 999;
        }

        .action {
            // .lib-css(width, @am-actions-secondary__size);
            // .lib-css(height, @am-actions-secondary__size);
            width: $am-actions-secondary__size;
            height: $am-actions-secondary__size;
            background: $gray-100;
        }

        .action:before {
            // .lib-css(line-height, @am-actions-secondary__size);
            line-height: $am-actions-secondary__size;
        }
    }

    .product-item-actions .tocart {
        // .lib-css(background, @am-button-secondary__background);
        box-shadow: 0 -2px 0 rgba(0, 0, 0, .15) inset;
        border-radius: 3px;
        border: 0;
    }
}

.product-info-main .product-add-form {
    text-align: left;
}

.products.list {
    .swatch-attribute .amcart-error {
        display: none;
    }
}

.am-slider.owl-carousel {
    .owl-nav {
        position: absolute;
        top: 50%;
        width: 100%;
        // .lib-css(transform, translateY(-50%), 1);
        transform: translateY(-50%);
        pointer-events: none;
    }

    .owl-prev {
        float: left;
        margin-left: map-get($spacers, 1);
        pointer-events: auto;
    }

    .owl-next {
        float: right;
        margin-right: map-get($spacers, 1);
        ;
        pointer-events: auto;
    }

    .owl-nav .disabled {
        opacity: .3;
    }

    .product-item {
        margin: 0 auto;
    }

    .product-item-info:hover {
        box-shadow: none;
        border: 0;
        margin: 0;
        padding: 0;
    }
}

.am-slider.arrow {
    & {
        width: 18px;
        height: 35px;
        // .lib-css(background, @am-slider-arrow__background);
        background: $am-slider-arrow__background;
        cursor: pointer;
    }

    &.-left {
        // .lib-css(transform, rotate(180deg), 1);
        transform: rotate(180deg);
    }
}

.am-photo-container {
    display: none;
    // .lib-vendor-prefix-display();
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

// Added alignment for the sliders item
.amcart-message-box .owl-stage {
    & {
        // .lib-vendor-prefix-display(flex);
        display: flex;
    }

    .product-item,
    .product-item-info {
        height: 100%;
    }

    .product-item-info {
        // .lib-vendor-prefix-display(flex);
        display: flex;
        // .lib-css(flex-flow, column, 1);
        flex-flow: column;
        // .lib-css(justify-content, space-between, 1);
        justify-content: space-between;
    }
}

.am-related-box .owl-stage {
    & {
        // .lib-vendor-prefix-display(flex);
        display: flex;
        margin: auto;
    }

    .product-item,
    .product-item-info {
        height: 100%;
    }

    .product-item-info {
        // .lib-vendor-prefix-display(flex);
        display: flex;
        // .lib-css(flex-flow, column, 1);
        flex-flow: column;
    }
}

.am-related-title {
    & {
        margin: 1.25rem 2.5rem;
        font-size: $font-size-base;
        height: 1px;
        background: $gray-400;
        line-height: 0;
    }

    >.am-title {
        padding: 0 map-get($spacers, 3);
    }
}

#amimg-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
}

#amprogress {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(11, 11, 11, .1) 0%, rgba(11, 11, 11, .6) 100%) repeat-x rgba(11, 11, 11, .2);
}

div.swatch-option-tooltip {
    z-index: 99999;
}

#confirmBox .error {
    margin-top: map-get($spacers, 4);
    margin-right: map-get($spacers, 3);
    margin-left: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 1);
}

[data-post-ajax] {
    cursor: pointer;
}

.amcart-message-box {

    // Override magento styles
    .bundle-options-wrapper {
        margin-top: 25px;
    }

    .bundle-options-wrapper input[type="radio"] {
        width: $spacer;
    }

    .bundle-options-wrapper p.required {
        color: $red;
    }

    .bundle-options-wrapper .field.qty>.label {
        display: inline-block;
        margin: map-get($spacers, 2) 0;
        font-weight: 600;
    }

    .bundle-options-wrapper .input-text.qty {
        text-align: center;
        width: 54px;
    }

    .block-bundle-summary {
        display: none;
    }
}

.page-product-downloadable .amcart-message-box {
    .product-add-form {
        margin-bottom: 0;
    }

    .product-options-bottom,
    .product-options-wrapper {
        float: none;
        width: auto;
    }

    .box-tocart .field.qty {
        display: block;
        margin-bottom: map-get($spacers, 1);
    }
}

// }

//
//  Tablet +
//  --------------------------------------------

// .media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__m) {
@include media-breakpoint-up($main-breakpoint) {

    .amcart-message-box,
    .amcart-message-box .related {
        // .lib-css(width, @am-confirmbox__width);
        width: $am-confirmbox__width;
    }

    .am-related-box,
    .am-related-box .related {
        // .lib-css(width, @am-confirmbox__width);
        width: $am-confirmbox__width;
    }

    #confirmBox {
        & {
            max-height: 90vh;
        }

        .products-grid .product-item-actions {
            margin-top: 0;
        }
    }

    #confirmOverlay {
        &.am-center {
            // .lib-vendor-box-align(center);
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

        &.am-right {
            // .lib-vendor-box-align(center);
            align-items: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            padding-right: 230px;
        }

        &.am-left {
            // .lib-vendor-box-align(center);
            align-items: center;
            // webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            padding-left: 230px;
        }

        &.am-top-right {
            // .lib-vendor-box-align(flex-start);
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            padding: 130px 230px 0 0;
        }

        &.am-top-left {
            // .lib-vendor-box-align(flex-start);
            align-items: flex-start;
            // webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            padding: 130px 0 0 230px;
        }

        &.am-top {
            justify-content: center;
            // .lib-vendor-box-align(flex-start);
            align-items: flex-start;
            padding-top: 130px;
        }
    }

    .amcart-message-box .products {
        .product-item {
            margin: 0;
            // .lib-css(width, @am-product-item__width);
            width: $am-product-item__width;
        }
    }

    .am-related-box .related .products {
        .item.product:hover .am-actions-wrapper {
            top: 0;
        }

        .product-item {
            margin: 0;
            padding: 0;
            // .lib-css(width, @am-product-item__width);
            width: $am-product-item__width;
        }

        .product-item-actions .secondary-addto-links {
            & {
                display: none;
            }

            &.am-actions-wrapper {
                display: block;
            }
        }
    }

    .amcart-confirm-buttons {
        .button {
            width: 42%;
        }

        .am-btn-left {
            // .lib-css(margin-right, @am-button__m__margin);
            margin-right: map-get($spacers, 4);
        }

        .timer {
            display: inline;
        }
    }

    .am-slider.owl-carousel {
        .owl-prev {
            margin-left: map-get($spacers, 2);
        }

        .owl-next {
            margin-right: map-get($spacers, 2);
        }
    }
}
