.cms-page-view {
    .breadcrumbs-wrapper {
        display: none;
    }

    .page-title {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        color: $primary;
    }

    .page-main {
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-family: $headings-font-family;

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: 1rem;
        }
    }

    p {
        color: $gray-600;
    }
}
