@import "module/variables";
@import "module/base";
@import "module/minipage";
@import "module/qty";
@import "module/mixins";

#confirmOverlay.am-center {
    z-index: 10000 !important;

    .amcart-confirm-block {
        padding: 1rem;
        color: $black;
        background-color: $white;

        @include media-breakpoint-down($main-breakpoint) {
            width: 90vw;
            margin: 0 auto;
        }

        .price-container {
            display: none;
        }

        .added-item {
            color: $primary;
        }

        .field.downloads .label {
            color: $primary;
        }

        .field.downloads .choice .label {
            color: $gray-800;
        }

        .points-loader {
            display: none;
        }

        .am-btn-right {
            border: 1px solid;
            font-size: inherit;
            line-height: inherit;
            border-radius: 0.5rem;
            box-shadow: none;
            @include button-variant($secondary, $secondary);
        }

        .am-btn-left {
            border: 1px solid;
            font-size: inherit;
            line-height: inherit;
            border-radius: 0.5rem;
            box-shadow: none;
            @include button-outline-variant($primary);
        }
    }
}
