.header-account, .navbar-account {
    .icon {
        @include svg-icon(1.4rem, 1.4rem, $icon-user, $primary);
    }
    .text {
        display: none;
    }
}

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
    .page-main {
        padding-bottom: 4rem;
    }

    .page-title-wrapper {
        margin: 4rem 0;
        color: $primary;
    }

    .login-container {

        .block-title {
            margin-bottom: 2rem;
            font-size: 2rem;
            font-weight: 400;
        }
    }
}

.account {
    .rewards-links {
        display: none;
    }
    
    .toolbar {
        &.bottom {
            .pager {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .pages {
                    display: block;

                    .pagination {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.account-nav {
    .items {
        .item {
            width: 100%;

            strong {
                width: 100%;
            }
        }
    }
}

.popup-authentication-wrapper {
    .modal-popup {
        z-index: 1076 !important;

        .block-authentication {
            @include make-row();

            > .block {
                @include make-col-ready();
                @include make-col(6);

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }
            }
            .block-title {
                margin-bottom: 1rem;
                font-size: $h5-font-size;
            }

            .actions-toolbar {
                .action.secondary {
                    @include button-outline-variant($primary);
                }
            }
        }
    }
    .modals-overlay {
        z-index: 1070 !important;
    }
}

.offcanvas-account {
    left: auto;
    right: 0;
    transform: translateX(100%);

    &.expanded {
        transform: translateX(0);
    }
}
