.message.cookie {
    position: fixed;
    bottom: 2em;
    left: 3em;
    padding: 2em;
    max-width: 30em;
    background: $white;
    z-index: 1000;
    box-shadow: -1px 2px 25px -9px rgba(0, 0, 0, 0.21);

    .h3, p {
        margin-bottom: 1em;
    }
    .h3 {
        text-transform: uppercase;
    }

    .cookie-small-link {
        margin-top: 1em;
        font-size: 0.8em;
    }
    
    @include media-breakpoint-down(md) {
        left: 0;
        bottom: 0;
        width: 100%;
    }
}
