.home-cta {
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    background-color: $primary;

    a {
        color: $white;
        font-size: $h4-font-size;
        font-weight: 700;

        @include media-breakpoint-down($main-breakpoint) {
            font-size: 1rem;
        }
    }
}

.cms-index-index {
    .page.messages {
        margin-top: 1rem;
    }

    .product-item {
        @include media-breakpoint-down($main-breakpoint) {
            padding: 0 4rem;
        }
    }
}

.hero-block {
    .hero-links-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0.25rem 0;

        .slide-item {
            display: block;
            padding: 0.5rem 1rem;
            width: 100%;
            border-left: 0.3rem solid transparent;
            cursor: pointer;

            &.active {
                border-color: $primary;
            }

            .hero-title {
                color: $primary;
                font-size: 2rem;
                font-weight: 700;
            }

            .hero-sub-title {
                color: $gray-700;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            padding-top: 0;

            .slide-item {
                .hero-title {
                    font-size: 1.5rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .hero-banner-list {
        position: relative;
        height: 100%;
        border-radius: 0.5rem;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.3s ease-out;

        .hero-banner-image {
            img {
                border-radius: 0.5rem;
            }
        }

        &.preload {
            max-height: 33rem;
            opacity: 0;
        }
    }
}

.hero-slider {
    .hero-banner-list {
        position: relative;
        height: 100%;

        .hero-banner-image {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            &.show {
                opacity: 1;
                pointer-events: auto;
            }

            img {
                display: block;
                width: 100%;
                border-radius: 0.5rem;
            }
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }
}

.home-title {
    padding-bottom: 1rem;
    color: $primary;
    font-size: $h1-font-size;
    font-weight: 700;
     @include media-breakpoint-down($main-breakpoint) {
        font-size: 1.7rem;
    }
    i {
        font-weight: 300;
    }
}

.home-products {
    position: relative;

    .product-list-wrapper {
        position: relative;
        min-height: 10rem;
    }

    .product-list-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .home-title {
            display: flex;
            align-items: center;
            padding-bottom: 0;

            .icon {
                margin-right: map-get($spacers, 2);

                &::before {
                    display: none;
                }

                &[class*="compact-disc"] {
                    @include svg-icon(2.5rem, 2.5rem, $icon-compact-disc, $primary);
                }

                &[class*="download"] {
                    @include svg-icon(2.5rem, 2.5rem, $icon-download, $primary);
                }
            }
        }

        .btn-viewall {
            display: inline-flex;
            align-items: center;
            column-gap: 0.25rem;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            color: $gray-600;

            .icon-arrow-right {
                @include svg-icon($font-size-sm, $font-size-sm, $icon-arrow-right, $gray-600);
            }
        }

        @include media-breakpoint-down(md) {
            .categories-list {
                display: none;
            }
        }
    }

    .loading-mask {
        position: absolute;

        .loader {
            padding: 1rem;

            &:before {
                @include svg-icon(2rem, 2rem, $icon-spinner, $gray-600);
                animation: fa-spin 2s infinite linear;
            }

            img {
                display: none;
            }
        }
    }

    .slick-arrow {
        background: $photo-slider-arrow-bg;
        color: $photo-slider-arrow-color;
        top: 40%;

        i {
            font-weight: 700;
        }

        &:before {
            @include svg-icon(1.25rem, 1.25rem, $icon-angle-left, $photo-slider-arrow-color);
        }

        span {
            display: none;
        }

        &.slick-next {
            &:before {
                @include svg-icon(1.25rem, 1.25rem, $icon-angle-right, $photo-slider-arrow-color);
            }
        }

        &.slick-prev {
            &:before {
                @include svg-icon(1.25rem, 1.25rem, $icon-angle-left, $photo-slider-arrow-color);
            }
        }
    }

    .products-grid {
        .product-item {
            .product-item-photo {
                margin-bottom: 0.5rem;

                @include media-breakpoint-down($main-breakpoint) {
                    .product-image-container {
                        width: 100%;

                        .product-image-wrapper {
                            width: 100%;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .btn-secondary {
                font-size: $font-size-sm;
            }

            .product-item-link {
                min-height: 3.5rem;
                color: $gray-800;
                font-size: $h5-font-size;
                font-weight: 400;
                text-decoration: none;

                @include media-breakpoint-only(lg) {
                    font-size: $h6-font-size;
                }
            }

            .product-item-price-link {
                display: inline-block;
                color: $gray-800;
                font-size: $h5-font-size;
                font-weight: 700;
                text-decoration: none;

                .price-box {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;

                    .old-price {
                        .price {
                            font-size: $font-size-base;
                        }
                    }
                }
            }
            @include media-breakpoint-down($main-breakpoint) {
                padding: 0;
            }
        }
    }
}

.home-blocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;

    .home-block {
        width: 33%;
        transition: opacity 0.3s ease;

        &.home-block-bottom {
            width: 49%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        img {
            width: 100%;
            border-radius: 1rem;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    @include media-breakpoint-only(xs) {
        display: block;
        margin-bottom: 0;

        .home-block {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

.cms-index-index {
    .page-main {
        margin-bottom: 3rem;
        color: $gray-600;

        .page-title {
            color: $primary;
        }
    }
}

.loading-wrapper-wrapper {
    opacity: 0;
    transition: opacity 0.5s 0.5s ease;
}
