.block-products-list {
    padding: 1rem 0;

    .block-title {
        color: $primary;
        margin-bottom: 2rem;
        font-size: $h2-font-size;
        font-weight: 700;
    }

    .slick-arrow {
        top: 40%;
        background: $photo-slider-arrow-bg;
        color: $photo-slider-arrow-color;
        font-size: $photo-slider-arrow-size;

        i {
            font-weight: 700;
        }
    }

    .product-item-actions {
        gap: 0.3125rem;
        .actions-primary {
            width: 50%;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
            & > form {
                width: 100%;
            }
        }
    }
    .more-info {
        padding: 0.45rem 0.656rem;
        text-align: center;
        border-radius: 0.219rem;
        & > span {
            font-size: 0.766rem;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .rewards__product-points {
        display: none;
    }
}

.home-products {
    .products-grid {
        .product-item {
            @include media-breakpoint-down($main-breakpoint) {
                padding: 0 0.4688rem;
            }

            .product-item-link {
                @include media-breakpoint-down(lg) {
                    height: 2rem;
                    min-height: 0 !important;
                    margin-top: 0.2rem;
                    font-size: 1.25rem !important;
                }
            }
        }
    }
}

.product-list-title {
    display: flex;
    flex-direction: column;
    align-items: stretch !important;
    .title-tabs-container {
        align-items: center;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: initial;
        }
    }
}

.nav-tabs {
    .btn {
        &:hover {
            a {
                color: white;
                text-decoration: none;
            }
        }
        &.active {
            a {
                color: white;
                &:hover {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0.7rem;
    }
}
