.rewards-notification-block {
    padding: 0;
    list-style: none;

    > li {
        position: relative;
        padding: $alert-padding-y $alert-padding-x;
        margin-bottom: $alert-margin-bottom;
        border: $alert-border-width solid transparent;
        @include border-radius($alert-border-radius);
        @include alert-variant(theme-color-level('success', $alert-bg-level), theme-color-level('success', $alert-border-level), theme-color-level('success', $alert-color-level));

        p {
            margin-bottom: 0;
        }
    }
}
#block-rewards-form {
    display: block !important;
    background: $gray-100;
    border-radius: $border-radius;

    .title {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
        background: $gray-200;
        border-radius: $border-radius;
        cursor: pointer;

        &:after {
            @include svg-icon(1.5rem, 1.5rem, $icon-chevron-down, $primary);
        }
    }
    &.active  {
        .title {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &:after {
                @include svg-icon(1.5rem, 1.5rem, $icon-chevron-up, $primary);
            }
        }
    }
    .content {
        padding: 1rem;
    }
    .input-box {
        margin-bottom: 1rem;

        label {
            display: none;
        }
    }
    .buttons-container {
        margin-bottom: 1rem;

        .action {
            @include button-outline-variant($primary);

            &:first-of-type {
                @include button-variant($primary, $primary);
            }
        }
    }
}
.reward-message {
    .alert {
        margin-top: 2rem;
    }
}
