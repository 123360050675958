header {
    margin-bottom: 0;

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;
    }

    .top-wrapper {
        padding: 0;
        background: $gray-200;

        ul {
            li {
                +li {
                    margin-left: map-get($spacers, 2);
                }
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }

    .header-top-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .logo {
        display: block;

        img {
            height: 2rem;
        }
    }

    .header {
        padding: 1rem 0;

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}

.breadcrumbs-wrapper {
    margin-top: $spacer;
}

.navbar-header-left,
.navbar-header-right {}

.navbar-header-center {
    text-align: center;
}


.back-to-top {
    height: 3rem;
    width: 3rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 1rem;
    background-color: $primary;
    color: $white;
    font-size: 1.2rem;
    line-height: 3rem;
    text-align: center;
    opacity: 0;
    transform: translateX(1rem);
    box-shadow: 3px 10px 27px -6px rgba(87, 87, 87, 1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 100;

    &.show {
        opacity: 1;
        transform: none;
        pointer-events: auto;
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.enter-splash {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    font-family: $headings-font-family;
    z-index: 20000;
    transition: opacity 0.3s ease;

    .enter-splash-body {
        padding: 5rem;
        background-color: $white;
        color: $gray-600;
        border-radius: 0.5rem;


        @include media-breakpoint-down($main-breakpoint) {
            height: 100vh;
            padding: 5rem 2rem;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    .enter-splash-title {
        margin-bottom: 4rem;
        color: $primary;
        text-align: center;

        .icon {
            &-exclamation-triangle {
                margin-bottom: map-get($spacers, 1);
                @include svg-icon(2.75rem, 2.75rem, $icon-exclamation-triangle, $primary);
                vertical-align: middle;
            }
        }
    }

    .enter-splash-buttons {
        margin-top: 4rem;
        text-align: center;

        a {
            width: 13rem;
            margin-right: 1rem;
            color: $white;

            &:last-of-type {
                color: $primary;

                &:hover {
                    color: $white;
                }
            }

            @include media-breakpoint-down($main-breakpoint) {
                margin-bottom: 2rem;
            }
        }
    }

    .enter-splash-footnote {
        margin-top: 2rem;
        text-align: center;
    }

    &.hidefade {
        opacity: 0;
        pointer-events: none;
    }
}
