.category-sex-actions {
    .category-view {
        display: none;
    }

    .categories-grid {
        .item {
            border-radius: 0.5rem;
            transition: opacity 0.3s ease;

            img {
                border-radius: 1rem;
            }

            .category-name {
                font-weight: 700;

                a {
                    color: $gray-600;
                }
            }

            &:hover {
                opacity: 0.5;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: 1rem;
        }
    }
}


.brand-legend {
    min-height: 48.97px;
}

.brand-legend-list {
    padding: 0.714em 0;
    list-style: none;
    border-top: 2px solid $gray-200;
    border-bottom: 2px solid $gray-200;
    text-align: center;

    li {
        display: inline-block;

        >a {
            padding: 0.25rem;
            font-size: $font-size-lg;
            font-family: $headings-font-family;
            font-weight: 700;
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        background: $white;
        z-index: 1000;
    }
}

.brands-list {
    padding: map-get($spacers, 4) 0;

    .brand-letter {
        color: $black;
        font-size: $h1-font-size * 1.5;
        font-family: $headings-font-family;
        font-weight: 700;
        text-align: center;

        @include media-breakpoint-down($main-breakpoint) {
            text-align: left;
        }
    }

    .items {
        padding: 0;
        list-style: none;
        -webkit-columns: 4;
        columns: 4;

        li {
            display: block;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid-column;

            a {
                color: $gray-700;
            }

            .durable {
                margin-left: map-get($spacers, 2);
                color: $secondary;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            -webkit-columns: 2;
            columns: 2;
        }
    }

    +.brands-list {
        border-top: 2px solid $gray-200;
    }
}
