.filter-item {
    .filter-options-title {
        margin-bottom: map-get($spacers, 2);
        color: $primary;
        font-size: $h5-font-size;
        text-transform: uppercase;
    }

    a {
        display: inline-block;
        margin: 0.25rem 0;
        color: $gray-600;
        font-size: $font-size-base;
        font-weight: 400;
    }

    + .filter-item {
        padding-top: 1rem;
        border-top: 1px solid $gray-200;
    }

    .amshopby-search-box {
        margin-bottom: 1rem;

        input {
            @extend .form-control;
            height: 2.5rem;
        }
    }

    .items {
        .item {
            input {
                display: none;

                &:checked {
                    + a:before {
                        font-weight: 900;
                        color: $primary;
                    }
                }
            }

            a {
                display: inline-flex;
                align-items: baseline;
                column-gap: 0.25rem;

                &.amshopby-link-selected {
                    span {
                        &,
                        &.count {
                            font-weight: 700;
                            color: $gray-800 !important;
                        }
                    }
                }
            }
        }
    }
}

.am-filter-items-attr_category_ids {
    input {
        display: none;
    }
}

.block.filter {
    .page-title-wrapper {
        margin-bottom: 2rem;
        color: $primary;

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }

    .block-subtitle {
        margin-bottom: 0.5rem;
        color: $primary;
        font-weight: 400;
        font-size: $h6-font-size;
        text-transform: uppercase;
    }

    .clear.filter-clear {
        padding-left: 0;
        color: $gray-600;
        background-color: $white;
        border: none;
        text-align: left;
        text-decoration: underline;

        .icon {
            &-trash-alt {
                margin-bottom: -0.125rem;
                @include svg-icon(1rem, 1rem, $icon-trash, $gray-600);
            }
        }
    }

    .amshopby-filter-current {
        .amshopby-item {
            position: relative;
            display: inline-flex;
            margin-bottom: 0.5rem;
            padding: 0.25rem 0.5rem;
            background: $primary;
            color: $white;
            font-weight: 700;
            border-radius: 0.5rem;
            cursor: pointer;
            align-items: center;

            &:after {
                @include svg-icon($font-size-sm, $font-size-sm, $icon-times, $white);
                margin-left: 0.5rem;
            }

            .amshopby-filter-name {
                display: none;
            }

            .amshopby-remove {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    pointer-events: auto;
                    content: "";
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
    }

    .filter-options .items .item .count {
        color: $gray-600;
    }

    .filter-item {
        .am-show-more {
            @extend .btn-outline-primary;
            margin-bottom: map-get($spacers, 3);
            text-decoration: none;

            &::after {
                display: none;
            }

            &::before {
                display: none;
            }
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .amshopby-slider-container {
        .am-slider {
            .ui-slider-handle {
                top: 0;
                width: 1rem;
                height: 1rem;
                background: $primary;
            }
        }

        .ui-slider-range {
            height: 0.4rem;
            background: $primary;
        }

        .ui-slider-horizontal {
            height: 0.4rem;
        }

        .amshopby-slider-display {
            margin-top: 1rem;
            color: $gray-600;
        }
    }
}
