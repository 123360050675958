// .am-flex(
//     $horiz-pos: none,
//     $vert-pos: none,
//     $wrap: none
// ) {
//     & {
//         display: flex;
//     }

//     & when not ($horiz-pos = none) {
//         justify-content: $horiz-pos;
//     }

//     & when not ($vert-pos = none) {
//         align-items: $vert-pos;
//     }

//     & when not ($wrap = none) {
//         flex-wrap: $wrap;
//     }
// }
