.block-rewards-account-summary {
    .block-title {
        padding-bottom: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 2);
        border-bottom: 1px solid $gray-100;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h5-font-size);

        .edit,
        .view {
            margin-left: map-get($spacers, 3);
            @include font-size($font-size-sm);
        }
    }
    &.block {
        margin-bottom: map-get($spacers, 5);
    }
}
