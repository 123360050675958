.checkout-index-index {
    header .logo {
        display: block;
        padding: 3rem 0;

        img {
            display: block;
            height: 2.5rem;
        }
    }

    .progress-authetication-wrapper .opc-progress-bar > li._active span {
        border-color: $primary;
    }

    .step-title {
        padding: 2rem 0;
        padding-bottom: 1rem;
    }

    .field {
        position: relative;
        margin-bottom: map-get($spacers, 3);
        padding-bottom: map-get($spacers, 4);

        div {
            &.mage-error,
            &.field-error {
                position: absolute;
                top: inherit;
                bottom: 0;
            }
        }
    }

    .form-login,
    .block-customer-login {
        .field {
            margin-bottom: 1rem;
        }
    }

    .action-edit {
        text-decoration: underline;
    }

    .totals-tax {
        td {
            padding-right: 0;
            text-align: right;
        }
    }

    .block-new-customer {
        .actions-toolbar {
            .primary,
            .secondary {
                display: block;
            }

            .secondary {
                margin-top: 1rem;
            }
        }
    }

    .float-label label {
        top: 1.6rem;
    }

    .button.action.continue.primary {
        font-size: 1.5rem;
    }

    .actions-toolbar > .primary .primary.checkout {
        padding: 0.75rem 1rem;
        font-size: $font-size-lg;
    }
    .minicart-items-wrapper {
        .item.message {
            margin-top: 1rem;
        }
    }

    .icon {
        &-info {
            @include svg-icon(0.875rem, 0.875rem, $icon-info, $gray-800);

            &::before {
                display: none;
            }
        }
    }

    .opc {
        .action-show-popup {
            @extend .btn-secondary;
        }

        .addresses {
            .shipping-address-items {
                column-gap: map-get($spacers, 2);

                @include media-breakpoint-down(md) {
                    row-gap: map-get($spacers, 3);
                }

                .shipping-address-item {
                    flex: 0 0 49%;
                    padding: map-get($spacers, 3);
                    border: 1px solid $gray-500;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        width: 100%;
                        flex: 0 0 100%;
                    }

                    &:hover {
                        border-color: $primary;
                    }

                    &.selected-item {
                        position: relative;
                        border-color: $primary;

                        &::after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            border: 1px solid $primary;
                            border-top: 0;
                            border-right: 0;
                            background-color: $primary;
                            @include svg-icon(1.5rem, 1.5rem, $icon-check, $white);
                        }
                    }

                    .action-select-shipping-item {
                        @extend .btn-primary;
                        color: $white;
                        border-color: $primary;

                        &:hover {
                            background-color: darken($primary, 5);
                            border-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.ampromo-items-add {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    @include alert-variant(#d3e2f0, #c1d6eb, #12395f);
}
.ampromo-item-buttons {
    .action.tocart.primary {
        width: 100%;
        background-color: $orange;
        border-color: $orange;
    }
}

.table-checkout-shipping-method {
    margin-bottom: 2rem;

    .row .col {
        &.col-radiobutton {
            flex-grow: initial;
        }

        &.col-method {
            margin-bottom: 0.75rem;
            font-weight: 700;
        }

        &.col-carrier {
            display: none;
        }
    }

    .row {
        cursor: pointer;
    }
}

.checkout-onepage-success {
    .sub-header {
        padding-top: 4rem;
        padding-bottom: 6rem;

        .row {
            justify-content: center;
        }
    }

    .checkout-success-wrapper {
        padding-bottom: 8rem;

        .page-title {
            color: $primary;
        }

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: 4rem;

            .checkout-success {
                padding-bottom: 4rem;
            }
        }
    }

    .action {
        &.downloadable {
            @extend .btn;
            @extend .btn-primary;

            margin-bottom: map-get($spacers, 3);
        }
    }
}

.checkout-index-index {
    .opc {
        .checkout-payment-method {
            .discount-code {
                &._active {
                    .payment-option-title {
                        &::after {
                            @include svg-icon(1.5rem, 1.5rem, $icon-chevron-up, $gray-900);
                        }
                    }
                }
                .payment-option-title {
                    &::after {
                        @include svg-icon(1.5rem, 1.5rem, $icon-chevron-down, $gray-900);
                    }
                }
            }
        }
    }

    .progress-authetication-wrapper {
        .opc-progress-bar {
            & > li {
                & + li {
                    &::before {
                        @include svg-icon(0.85rem, 0.85rem, $icon-chevron-right, $gray-900);
                    }
                }

                & > a {
                    &.opc-progress-bar-item-cart {
                        &::before {
                            @include media-breakpoint-down($main-breakpoint) {
                                @include svg-icon(0.875rem, 0.875rem, $icon-shopping-basket, $gray-900);
                            }
                        }
                    }
                }
                @include media-breakpoint-down($main-breakpoint) {
                    &._active {
                        span {
                            color: $secondary;
                            border-color: $secondary;
                        }

                        &::before {
                            color: $secondary;
                            border-color: $secondary;
                        }
                    }

                    &._complete {
                        a {
                            color: $secondary;
                            border-color: $secondary;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down($main-breakpoint) {
    .checkout-index-index .opc-estimated-wrapper .minicart-wrapper .showcart:before {
        @include svg-icon(0.875rem, 0.875rem, $icon-shopping-basket, $gray-900);
    }
}

.shippingmethod-comment {
    flex-grow: 1 !important;
    margin-bottom: 2rem;
}

.checkout-index-index fieldset.street > .control {
    display: block;

    .field {
        max-width: none;
        flex: none;

        &.additional .label {
            display: none;
        }
    }
}

table {
    .totals-tax {
        th.mark {
            padding-left: 0;
        }
    }
}

.totals-wrapper .total-rules {
    display: none;
}

.payment-method-content {
    iframe {
        width: 100%;
        border: 0;
    }
}

.checkout-index-index {
    .payment-method {
        &.paynl {
            .payment-method-title {
                .label {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;

                    img {
                        margin-left: 0.5rem;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .payment-method-title {
        display: flex;
        align-items: center;

        &[id="emerchantpay_checkout"] {
            &[payment-gateway="Visa / Mastercard"] {
                display: flex;
                align-items: center;
                flex-direction: row;
                &::after {
                    content: "";
                    background-image: url(../images/mastercard-logo.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: inline-block;
                    height: 2.5rem;
                    width: 4.5rem;
                    margin-left: 0.19rem;
                }

                label.label[for="emerchantpay_checkout"] {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    &::after {
                        content: "";
                        background-image: url(../images/visa-logo.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        display: inline-block;
                        height: 2.5rem;
                        width: 4.5rem;
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        label.label[for="banktransfer"] {
            display: flex;
            align-items: center;
            flex-direction: row;
            &::after {
                content: "";
                background-image: url(../images/sepa-v2.svg);
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                height: 1.125rem;
                width: 3.5rem;
                margin-left: 0.5rem;
            }
        }

        .credit-card-types {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            .item {
                width: 3rem;
            }
        }

        .radio {
            margin-top: 0.05rem !important;
        }
    }
}

.field:has(input[attr="region"]) {
    display: none;
}
