.block-newsletter {
    margin-bottom: 3rem;

    .btn {
        padding: 0 2rem;
        border-radius: 0 2rem 2rem 0;

        @include media-breakpoint-between(sm, lg) {
            padding: 0 0.5rem;
        }
    }

    .newsletter {
        @include media-breakpoint-down($main-breakpoint) {
            .input-group {
                justify-content: center;

                input {
                    max-width: 350px;
                }
            }
        }

        ::placeholder {
            color: $gray-500;
        }
    }

    div.mage-error {
        position: absolute;
        top: 3.5rem;
    }
}
