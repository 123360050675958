.products-grid {
    .product-item {
        margin-bottom: 2rem;

        .product-item-photo {
            margin-bottom: 0.5rem;

            @include media-breakpoint-down($main-breakpoint) {
                .product-image-container {
                    width: 100%;

                    .product-image-wrapper {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .price-box {
            font-weight: 700;

            .old-price {
                margin-right: 1rem;

                .price {
                    text-decoration: line-through;
                    color: $gray-400;
                }
            }

            .special-price {
                .price {
                    color: $primary;
                }
            }

            .price-label {
                display: none !important;
            }
        }

        .product-item-link {
            height: 3.5rem;
            overflow: hidden;
            color: $gray-800;
            font-size: $h5-font-size;
            font-weight: 400;
            text-decoration: none;
        }

        .product-item-price-link,
        .price {
            display: inline-block;
            color: $gray-800;
            font-size: $h5-font-size;
            font-weight: 700;
            text-decoration: none;

            .rewards__product-earn-points {
                display: none;
            }
        }

        .product-item-actions {
            margin-top: 0.25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .primary {
                font-size: $font-size-sm;
            }

            form {
                width: 49%;

                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }

            .tocart {
                width: 100%;
                transition: opacity 0.25s ease;

                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;
                    opacity: 0.5;
                }

                span {
                    .icon {
                        &-shopping-cart {
                            margin-top: -0.125rem;
                            @include svg-icon(0.925rem, 0.925rem, $icon-shopping-cart, $white);
                            vertical-align: middle;
                        }
                    }
                }
            }

            .more-info {
                @include button-variant($gray-500, $gray-500);
                color: $white;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                width: 49%;
                transition: opacity 0.25s ease;

                &:hover {
                    background-color: $gray-500;
                    border-color: $gray-500;
                    color: $white;
                    opacity: 0.5;
                }

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }
}

.toolbar {
    .pages {
        display: none;
    }

    .toolbar-sorter {
        display: block;

        .input-group {
            justify-content: flex-end;
        }

        .input-group-append {
            display: none;
        }

        .sort-asc {
            display: none;
        }
    }
}

.toolbar-footer {
    .pages {
        display: block;
    }

    .toolbar-sorter {
        display: none;
    }
}

.catalogsearch-result-index {
    .mst-search__result-tabs {
        display: none;
    }
}