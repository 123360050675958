.navbar {
    @include media-breakpoint-up($main-breakpoint) {
        padding: 0;
    }

    .col {
        @include media-breakpoint-down($main-breakpoint) {
            padding: 0;
        }
    }
}

.navbar-light .navbar-nav {
    font-family: $headings-font-family;

    @include media-breakpoint-up(lg) {
        display: flex;
        width: 100%;
        margin-left: -15px;
    }

    .nav-item {
        padding: 0.5rem 1rem;
        transition: background-color 0.3s ease;

        @include media-breakpoint-down(lg) {
            padding: 0.5rem 0;
        }

        &:hover {
            background-color: $white;
        }

        .btn-toggle {
            float: right;
            display: none;
            background-color: $primary;
            border-radius: 50%;
            height: 2.5rem;
            aspect-ratio: 1/1;
            line-height: 3rem;
            text-align: center;
            color: $white;
            padding: 0;

            &::after {
                @include svg-icon(1rem, 1rem, $icon-chevron-down, $white);
            }

            @include media-breakpoint-down($main-breakpoint) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            .nav-link {
                display: inline-block;
            }
        }

        &.show {
            .btn-toggle {
                &::after {
                    @include svg-icon(1rem, 1rem, $icon-chevron-up, $white);
                }
            }
        }
    }

    .nav-link {
        color: $black;
    }

    .dropdown {
        @include media-breakpoint-up(lg) {
            &:hover {
                .dropdown-menu {
                    display: flex;
                }
            }
        }
        .dropdown-menu {
            padding: 1rem;
            border: 0;
            border-radius: 0;
            text-transform: none;
            box-shadow: 0 7px 17px 0 rgba(0,0,0,0.08);

            .dropdown-item {
                padding: 0;
                padding-bottom: 0.5rem;

                &:hover {
                    background: none;
                }
            }
            a {
                display: block;
                padding-bottom: 0.5rem;
                color: $gray-600;

                &.menu-title-link, &.title {
                    margin-bottom: 1rem;
                    color: $primary;
                    font-weight: 700;
                    transition: opacity 0.3s ease;

                    &:hover {
                        opacity: 0.75;
                        text-decoration: none;
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 75%;
                        margin-top: 0.5rem;
                        border-bottom: 1px solid $gray-200;
                    }

                    &.title-spacer {
                        opacity: 0;

                        @include media-breakpoint-down($main-breakpoint) {
                            display: none;
                        }
                    }
                }

                &.menu-link-primary {
                    margin-bottom: 1rem;
                    color: $primary;
                    font-size: 1.1rem;
                    font-weight: 700;
                    transition: opacity 0.3s ease;

                    &:hover {
                        opacity: 0.75;
                        text-decoration: none;
                    }
                }

                &:first-of-type {
                    margin-bottom: 0.5rem;
                    font-family: $headings-font-family;
                    font-size: 1.2rem;

                    @include media-breakpoint-down($main-breakpoint) {
                        color: $gray-700;
                        font-size: 1rem;
                        padding-top: 1rem;
                        padding-bottom: 0;
                    }
                }

                @include media-breakpoint-down($main-breakpoint) {
                    color: $gray-700;
                }
            }
        }
    }
}
