$ampage-border__color: $gray-500;
$ampage-notice__background: $gray-100;

//
//  Common
//  ----------------------------------------------

// & when (@media-common = true) {
.page-products .amcart-minipage-wrap .product-item-info {
    width: 100%;
}

.amcart-minipage-wrap {
    .product-item-info {
        width: 100%;
    }

    .amcart-confirm-buttons {
        // .lib-vendor-prefix-display();
        display: flex;
        // .lib-vendor-prefix-flex-direction(column-reverse);
        // flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        column-gap: map-get($spacers, 2);
    }

    .amcart-confirm-buttons .am-btn-left,
    .amcart-confirm-buttons .am-btn-right {
        margin: auto;
        max-width: 260px;
        width: 100%;
        font-size: $font-size-lg;
    }

    .amcart-message-box {
        max-width: 320px;
    }

    .am-photo-container {
        margin: 0 25px 10px;
        margin: 0 map-get($spacers, 4) map-get($spacers, 2);
        width: initial;
        box-sizing: border-box;
        // .lib-css(border, 1px solid @ampage-border__color);
        border: $border-width solid $border-color;
    }

    .am-photo-container>.product-item-photo {
        display: block;
        margin: auto;
        width: 60%;
    }

    .am-photo-container .product-image-container {
        vertical-align: middle;
    }

    .product-options-bottom .price-box {
        display: none;
    }

    /* don't hide grouped price*/
    .product-options-bottom .grouped .price-box {
        display: block;
    }
}

.ampage-top-info,
.ampage-bottom-info {
    text-align: left;
}

.ampage-top-info {
    & {
        margin: 5px 25px 15px;
        margin: map-get($spacers, 1) map-get($spacers, 4) map-get($spacers, 3);

    }

    >.am-title {
        margin-bottom: map-get($spacers, 1);
        font-size: $font-size-lg;
        color: $primary;
        font-family: $font-family-sans-serif;
        font-weight: $headings-font-weight;
        @include font-size($h4-font-size);
    }

    >.am-rating .rating-summary {
        vertical-align: text-bottom;
    }

    >.am-price,
    >.am-price .price {
        @include font-size($h4-font-size);
        font-weight: $font-weight-bold;
    }
}

#confirmBox .reviews-actions .action {
    margin-left: $spacer;
    font-size: $font-size-sm;
    color: $black;
}

.ampage-bottom-info {
    >.am-description {
        display: none;
        margin: 0 map-get($spacers, 4);
        font-size: $font-size-sm;
    }

    .swatch-attribute {
        margin-bottom: map-get($spacers, 1);
    }
}

.amcart-product-notice {
    display: none;
    // display: inline-block;
    padding: map-get($spacers, 2) map-get($spacers, 4);
    font-size: $font-size-sm;
    // .lib-css(background, @ampage-notice__background);
    background: $gray-100;
}
.product-options-wrapper {
    .field {
        &.downloads {
            margin-left: 0;
        }
    }
}
// }

//
//  Only mobile
//  --------------------------------------------

// .media-width(@extremum, @break) when (@extremum = 'max') and (@break = @screen__m) {
.amcart-minipage-wrap .amcart-confirm-buttons .am-btn-left {
    padding: 0;
    background: none;
    color: $gray-500;
    box-shadow: none;
}

// }

//
//  Mobile +
//  --------------------------------------------

// .media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__s) {
@include media-breakpoint-down(sm) {

    .amcart-minipage-wrap {
        .amcart-message-box {
            position: relative;
            margin: 0 auto;
            padding: 40px 0 map-get($spacers, 2);
            width: 400px;
            max-width: initial;
        }

        .ampage-top-info {
            margin-top: map-get($spacers, 3);
        }
    }

    .amcart-product-notice {
        position: absolute;
        top: 0;
        left: 50%;
        width: 60%;
        // .lib-css(transform, translateX(-50%), 1);
        transform: translateX(-50%);
        border-radius: 0 0 7px 7px;
    }
}

// }

//
//  Tablet +
//  --------------------------------------------

@include media-breakpoint-down($main-breakpoint) {
    .amcart-minipage-wrap {
        .amcart-message-box {
            min-width: 700px;
        }

        .product-item-info {
            margin-top: 60px;
            min-height: 450px;
        }

        .am-photo-container {
            display: none;
            position: absolute;
            left: 0;
            margin: 0;
            padding: 0 25px;
            width: 50%;
            border: 0;
            box-sizing: border-box;
        }

        .am-photo-container>.product-item-photo {
            width: 100%;
            border: 0;
        }

        .am-photo-container .product-image-container {
            // .lib-css(border, 1px solid @ampage-border__color);
            border: $border-width solid $gray-400;
        }

        .amcart-confirm-buttons {
            clear: both;
            // .lib-vendor-prefix-flex-direction(row);
            display: flex;
            flex-direction: row;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
            justify-content: space-between;
            margin: 0 25px 40px;
        }

        .amcart-confirm-buttons .button {
            margin: 0;
            max-width: 305px;
            height: 70px;
        }
    }

    .amcart-product-notice {
        padding: map-get($spacers, 4) map-get($spacers, 5);
        width: initial;
        font-size: $font-size-sm;
    }

    .ampage-top-info {
        float: right;
        margin: 0;
        width: 50%;
    }

    .ampage-bottom-info {
        clear: both;
        float: right;
        margin-bottom: map-get($spacers, 4);
        width: 50%;
    }

    .ampage-top-info,
    .ampage-bottom-info {
        padding: 0 map-get($spacers, 4) 0 0;
        box-sizing: border-box;
    }

    .ampage-bottom-info {
        .am-description {
            margin: 0;
        }

        // Override magento styles
        .product-info-main {
            width: 100% !important;
        }

        .product-add-form {
            padding: 0;
            margin: 0;
        }
    }

    .ampage-top-info {
        .am-title {
            margin-bottom: map-get($spacers, 2);
            @include font-size($h3-font-size);
            white-space: nowrap;
        }

        .product-reviews-summary {
            margin-bottom: map-get($spacers, 2);
        }

        .product-reviews-summary .action {
            margin-left: map-get($spacers, 2);
        }
    }

    .ampage-bottom-info {
        .am-description {
            font-size: $font-size-sm;
            margin-top: map-get($spacers, 1);
        }

        .product-add-form {
            padding: 0;
        }
    }
}


//
//  Desktop +
//  --------------------------------------------

@include media-breakpoint-up($main-breakpoint) {
    .amcart-minipage-wrap {
        .amcart-message-box {
            width: 820px;
        }

        .amcart-confirm-buttons {
            margin: 0 35px 40px;
        }

        .amcart-confirm-buttons .button {
            max-width: 355px;
        }

        .am-photo-container {
            display: none;
            padding: 0 25px 0 35px;
        }

        .am-photo-container>.product-item-photo {
            text-align: left;
        }
    }

    .ampage-top-info,
    .ampage-bottom-info {
        padding: 0 35px 0 0;
    }

}
