.category-view {
    height: 18rem;
    margin-bottom: 3rem;

    .col-lg-6,
    .row,
    .category-image {
        height: 100%;

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem 0 0 1rem;
    }

    .category-description-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2rem;
        height: 100%;
        color: $white;
        background-color: $primary;
        border-radius: 0 1rem 1rem 0;

        .page-title {
            font-size: 2rem;
            width: 100%;
        }
    }

    .mobile-title-wrapper {
        display: none;
    }

    @include media-breakpoint-down($main-breakpoint) {
        height: auto;

        .mobile-title-wrapper {
            display: block;

            .page-title {
                color: $primary;
                text-align: center;
                font-size: 2rem;
                width: 100%;
            }
        }
    }
}

.toolbar-products {
    margin-bottom: 2rem;

    @include media-breakpoint-down($main-breakpoint) {
        display: flex;
        column-gap: 0.5rem;
    }

    .input-group {
        align-items: center;
    }

    .input-group-text {
        margin-right: 1rem;
        padding-left: 0;
        color: $gray-700;
    }

    .sort-dropdown {
        margin-right: 1rem;
        padding: 0.5rem 2rem;
        color: $gray-500;
        box-shadow: $input-box-shadow;

        &::after {
            @include svg-icon(1rem, 1rem, $icon-chevron-down, $black);
            vertical-align: middle;
            border: none;
        }
    }

    .sort-desc,
    .sort-asc {
        color: $gray-600;
        font-size: 1.75rem;

        i {
            font-weight: 700;
        }
    }
}

@include media-breakpoint-down(md) {
    .btn-layered {
        width: 50%;
        display: inline-block;
    }

    .toolbar-sorter {
        width: 48%;

        .input-group,
        .dropdown {
            width: 100%;

            .input-group-append {
                display: none;
            }
        }

        .btn-white {
            display: inline-block;
            width: 100%;
            color: $white;
            background-color: $primary;
        }
    }
}

.toolbar-footer {
    .btn-layered {
        display: none;
    }
}


.category-sale,
.category-sale-dl {
    .filter-item:first-of-type {
        display: none;
    }
}


.seo-category-description {
    margin-top: 5rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid $gray-300;

    .page-content {
        position: relative;
        margin-bottom: map-get($spacers, 3);
        max-height: 8rem;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 4rem;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
        }

        a {
            text-decoration: underline;
        }
    }

    .readmore-wrapper {
        margin-bottom: map-get($spacers, 3);

        .btn-outline-dark {
            padding: 0.25rem map-get($spacers, 3);
            border-width: 2px;
            font-weight: 600;

            span {
                font-size: $font-size-sm;
            }

            i {
                margin-left: map-get($spacers, 2);
            }
        }

        .more {
            display: block;
        }

        .less {
            display: none;
        }
    }

    &.open {
        .page-content {
            max-height: 200rem;

            &:after {
                display: none;
            }
        }

        .readmore-wrapper {
            .more {
                display: none;
            }

            .less {
                display: block;
            }
        }
    }
}
