footer.footer {
    padding-top: 3rem;
    background: $gray-100;
}

.footer-middle-wrapper {
    padding: map-get($spacers, 3) 0;

    .col-md-2,
    .col-md-4 {
        @include media-breakpoint-down($main-breakpoint) {
            margin: 1rem 0;
        }
    }
    .follow {
        text-align: center;

        .btn-outline-primary {
            &:hover {
                .icon-twitter {
                    @include svg-icon(0.875rem, 0.875rem, $icon-twitter, $white);
                }
            }

            .icon-twitter {
                @include svg-icon(0.875rem, 0.875rem, $icon-twitter, $black);
                vertical-align: middle;
            }
        }
    }
}

.footer-block {
    color: $primary;

    .block-title {
        margin-bottom: map-get($spacers, 2);
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $primary;

        &.label-collapse {
            &:after {
                @include svg-icon(1.25rem, 1.25rem, $icon-chevron-up, $primary);
                float: right;
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1.25rem, 1.25rem, $icon-chevron-down, $primary);
                }
            }
        }
    }

    .block-content {
        ul {
            padding: 0;
            list-style-type: none;

            li {
                padding: 0.3rem 0;
            }

            &.check {
                li::before {
                    margin-right: 0.5rem;
                    @include svg-icon(1rem, 1rem, $icon-check, $primary);
                    vertical-align: middle;
                }
            }
        }
    }
}

.block-payments {
    text-align: right;

    img {
        max-width: 22rem;
    }
}

.footer-logo {
    display: block;
    margin-bottom: 3rem;
    text-align: center;

    img {
        max-width: 18rem
    }

    @include media-breakpoint-down(sm) {
        margin-top: 3rem;
    }
}

.block-order-catalog {
    padding-bottom: 3rem;
    text-align: center;

    h3 {
        margin-bottom: 3rem;
        color: $primary;
    }

    .btn-order-catalog {
        padding: 0.5rem 0.75rem;
        width: 100%;
        background: $white;
        font-size: $font-size-sm;

        &:hover {
            background-color: $primary;
        }

        @include media-breakpoint-down($main-breakpoint) {
            max-width: 350px;
        }
    }
}

.footer-bottom-wrapper {
    padding: 2.5rem 0;
    background-color: $white;

    .copyright {
        display: inline-flex;
        align-items: center;
        column-gap: 0.25rem;
        padding-bottom: 1rem;
        font-size: $font-size-base;

        &::before {
            @include svg-icon(1rem, 1rem, $icon-copyright, $primary);
        }
    }

    .footer-extra-info-wrapper {
        font-size: 0.9rem;
        
        p {
            margin-bottom: 0rem;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;

        .container {
            padding: 0;

            .row {
                margin: 0;

                .col-md-6 {
                    padding: 0;
                }
            }
        }

        .copyright {
            display: block;
            padding: 2rem 0;
            background: $primary;
            color: $white;
            text-align: center;
        }

        .footer-extra-info-wrapper {
            display: block;
            padding: 2rem;
            text-align: center;
        }

        .block-payments {
            display: none;
        }
    }
}

.back-to-top {
    .icon-arrow-up {
        @include svg-icon(1rem, 1rem, $icon-arrow-up, $white);
    }
}

.grecaptcha-badge { 
    visibility: hidden; 
} 
