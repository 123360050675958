.field {
    div.mage-error, div.field-error {
        position: inherit;
    }
}
.checkout-cart-index {

    .reward-message {

        p.limit {
            margin: 0;
        }
    }

    .cart-container {
        font-family: $headings-font-family;
        margin-bottom: 5rem;

        .summary.title {
            padding: 2rem 0;
            padding-top: 1rem;
            color: $primary;
        }

        .cart-block.left,
        .cart-block {
            border-color: $gray-200;
        }

        .cart-block {
            padding: 2.5rem;
        }

        .cart {
            .item-info {
                padding: 1rem 0;

                .product-image-photo {
                    display: block;
                    width: 60%;
                    margin-left: 20%;
                }

                .cart-price-block {
                    color: $gray-500;
                }

                .product-item-name {
                    a {
                        color: $gray-600;
                        font-size: 1.1rem;
                        text-decoration: none;
                    }
                }

                .action-edit {
                    display: none;
                }

                .action-delete {
                    @include svg-icon(1.15rem, 1.15rem, $icon-trash, $body-color);
                }

                .spinbox {
                    display: none;
                    margin-top: 1rem;

                    .btn-spinbox {
                        color: $black;
                    }
                }
            }
        }

        .action.continue {
            color: $primary;
            font-weight: 400;

            span {
                text-decoration: underline;
            }

            &::before {
                color: $primary;
                font-weight: 400;
            }
        }

        .action.update {
            display: none;
            padding: 0.5rem 2rem;
        }

        .table-caption {
            display: none;
        }

        .discount-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            background-color: $white;
            border-radius: 50%;

            .icon {
                &-badge-percent {
                    @include svg-icon(1.15rem, 1.15rem, $icon-badge-percent, $primary);
                }
            }
        }

        #block-discount-heading {
            font-weight: 400;
        }

        .discount {
            &.active {
                .title {
                    &::after {
                        @include svg-icon(1.5rem, 1.5rem, $icon-chevron-up, $primary);
                    }
                }
            }

            .title {
                &::after {
                    @include svg-icon(1.5rem, 1.5rem, $icon-chevron-down, $primary);
                }
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            margin-top: 0;
            margin-bottom: 2rem;

            .cart-block {
                padding: 1rem 0;
            }
        }
    }

    .cart-empty {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
        padding: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
        border: ($border-width * 2) solid $gray-200;
        text-align: center;
    }

    .page-title-wrapper {
        padding: 2rem 0;
        padding-top: 1rem;
        border-color: $gray-200;
    }

    .page-title {
        margin-bottom: 0;
        color: $primary;
        font-size: 2rem;
    }
}

.cart-container {
    .cart {
        .item-info {
            .product-item-name {
                font-size: $font-size-lg;

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .table.totals {
        .totals-tax {
            display: none;
        }

        .totals.sub,
        .totals.shipping,
        .totals-tax,
        .grand.totals {
            th {
                font-size: 1.2rem;
                font-weight: 700;
            }

            td {
                color: $gray-600;
                font-size: 1.2rem;
                font-weight: 400;

                strong {
                    color: $gray-600;
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }
        }
    }
}
.cart-container button.checkout {
    padding: 1rem 1rem;
}

.block {
    &.crosssell {
        padding-bottom: 3rem;

        .block-title {
            display: block;
            margin-bottom: 2rem;
            color: $primary;
            font-size: $h1-font-size;

            strong {
                font-weight: 700;
            }
        }
    }
}

.checkout-cart-index{
    .icon {
        &-heart {
            display: inline-block !important;
            @include svg-icon(1.15rem, 1.15rem, $icon-heart-outline, $body-color);
            vertical-align: sub;
        }
    }
}