// Check `vendors/boostrap/mixins` for more mixins

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}

@mixin heading($arguments) {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    @include font-size($arguments);
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function svg-icon-color($svg, $old-fill, $new-fill) {
    $replaced-svg: str-replace($svg, $old-fill, $new-fill);
    $replaced-svg-url: escape-svg('#{$replaced-svg}');
    @return $replaced-svg-url;
}

// SVG Icon mixin with color support:
@mixin svg-icon($width, $height, $svg, $color: '#212529', $fromcolor: '#ffffff') {
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    background-image: svg-icon-color($svg, $fromcolor, $color);
    background-repeat: no-repeat;
    background-position: center;
}

// SVG Icon mixin with color support and hover color
@mixin svg-icon-hover($width, $height, $svg, $color: '#212529', $hovercolor: '#212529', $fromcolor: '#ffffff') {
    position: relative;
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    background-position: center;

    &::after,
    &::before {
        position: absolute;
        left: 0;
        content: '';
        display: inline-block;
        width: $width;
        height: $height;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity 0.3s ease;
        pointer-events: none;
    }

    &:after {
        background-image: svg-icon-color($svg, $fromcolor, $hovercolor);
        opacity: 0;
    }

    &:before {
        background-image: svg-icon-color($svg, $fromcolor, $color);
        opacity: 1;
    }

    &:hover {
        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }
    }
}
