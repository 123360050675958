//
//  Variables
//  ___________________________________

$am-text__font-size: $font-size-sm;
$am-confirmbox__width: 462px;
$am-required__color: #e02b27;
$am-button__width: 45%;
$am-button__margin: 0.625rem;
$am-button__m__margin: 1.25rem;
$am-button-secondary__background: #2D9CDB;
$am-actions-secondary__size: 1.875rem;
$am-product-item__width: 60%;
$am-slider-arrow__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAxOSAzNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGU+bmV4dDwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB1c2luZyBGaWdtYTwvZGVzYz48ZyBpZD0iQ2FudmFzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA2MDIgLTgwOSkiPjxnIGlkPSJuZXh0Ij48ZyBpZD0iR3JvdXAiPjxnIGlkPSJWZWN0b3IiPjx1c2UgeGxpbms6aHJlZj0iI3BhdGgwX2ZpbGwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwNjAyIDgwOSkiIGZpbGw9IiNCQkJCQkIiLz48L2c+PC9nPjwvZz48L2c+PGRlZnM+PHBhdGggaWQ9InBhdGgwX2ZpbGwiIGQ9Ik0gMTguMjAzMiAxNi44MDIzTCAxLjY5MjUxIDAuMjkxNTZDIDEuMzAzNzcgLTAuMDk3MTg2NiAwLjY4MDMwNiAtMC4wOTcxODY2IDAuMjkxNTYgMC4yOTE1NkMgLTAuMDk3MTg2NyAwLjY4MDMwNiAtMC4wOTcxODY3IDEuMzAzNzcgMC4yOTE1NiAxLjY5MjUxTCAxNi4wOTgxIDE3LjQ5OTFMIDAuMjkxNTYgMzMuMzA1N0MgLTAuMDk3MTg2NyAzMy42OTQ0IC0wLjA5NzE4NjcgMzQuMzE3OSAwLjI5MTU2IDM0LjcwNjZDIDAuNDgyMjY1IDM0Ljg5NzMgMC43Mzg5ODUgMzUgMC45ODgzNjkgMzVDIDEuMjM3NzUgMzUgMS40OTQ0NyAzNC45MDQ2IDEuNjg1MTggMzQuNzA2NkwgMTguMTk1OSAxOC4xOTU5QyAxOC41ODQ2IDE3LjgxNDUgMTguNTg0NiAxNy4xODM3IDE4LjIwMzIgMTYuODAyM1oiLz48L2RlZnM+PC9zdmc+);
$am-actions-icon-__background-color: #f0f0f0;
$am-actions-icon-mobile__color: #666;
$am-actions-icon-wishlist__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0wIDIwMFYwaDIwMGExMDAgMTAwIDkwIDAgMSAwIDIwMCAxMDAgMTAwIDkwIDAgMS0yMDAgMHoiIGlkPSJhIi8+PC9kZWZzPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0tMS0xaDMydjMySC0xeiIvPjx1c2UgeGxpbms6aHJlZj0iI2EiIGZpbGw9IiM2NjYiIHRyYW5zZm9ybT0icm90YXRlKC0xMzUgNTEgLTE4NSkgc2NhbGUoLjA3NTU3KSIgeT0iLTQ3NzUiIHg9Ii05NjciIHN0cm9rZS13aWR0aD0iMCIvPjwvc3ZnPg==);
$am-actions-icon-wishlist-hover__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0wIDIwMFYwaDIwMGExMDAgMTAwIDkwIDAgMSAwIDIwMCAxMDAgMTAwIDkwIDAgMS0yMDAgMHoiIGlkPSJhIi8+PC9kZWZzPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0tMS0xaDMydjMySC0xeiIvPjx1c2UgeGxpbms6aHJlZj0iI2EiIGZpbGw9IiNmZjBlMDAiIHRyYW5zZm9ybT0icm90YXRlKC0xMzUgNTEgLTE4NSkgc2NhbGUoLjA3NTU3KSIgeT0iLTQ3NzUiIHg9Ii05NjciIHN0cm9rZS13aWR0aD0iMCIvPjwvc3ZnPg==);
$am-actions-icon-compare__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoMzJ2MzJILTF6Ii8+PGc+PHBhdGggZmlsbD0iIzY2NiIgZD0iTTEyIDRoN3YyMmgtN3pNMSAyMGg3djZIMXpNMjIgMTJoN3YxNGgtN3oiLz48L2c+PC9zdmc+);
$am-actions-icon-compare-hover__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoMzJ2MzJILTF6Ii8+PHBhdGggZmlsbD0iI2ZmOTQxNiIgZD0iTTEyIDRoN3YyMmgtN3pNMSAyMGg3djZIMXptMjEtOGg3djE0aC03eiIvPjwvc3ZnPg==);
$amcart-qty-control__height: 1.875rem;
$amcart-qty-control__background: #b2b2b2;
$amcart-qty-refresh__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjQ2NCAxMC42MjVsMS4yMjIgMS41NjNBNy45MDQgNy45MDQgMCAwIDEgOS42NDMgMTVDNS40IDE1IDEuOTI5IDExLjYyNSAxLjkyOSA3LjVjMC0uMTg4IDAtLjQzOC4wNjQtLjYyNUgwbDIuODkzLTMuNzUgMi44OTMgMy43NUgzLjkyYy0uMDY0LjE4OC0uMDY0LjQzOC0uMDY0LjYyNSAwIDMuMTI1IDIuNTcyIDUuNjI1IDUuNzg2IDUuNjI1IDEuOTkzIDAgMy43OTMtMSA0LjgyMS0yLjV6bTIuODI5LTMuNzVDMTYuOTcgMy4wNjIgMTMuNjkzIDAgOS42NDMgMGE3Ljk5OCA3Ljk5OCAwIDAgMC01LjUyOSAyLjI1bDEuMjIyIDEuNWMxLjAyOC0xLjE4OCAyLjU3MS0xLjg3NSA0LjMwNy0xLjg3NSAyLjk1NyAwIDUuNCAyLjE4OCA1LjcyMSA1SDEzLjVsMi44OTMgMy43NSAyLjg5My0zLjc1aC0xLjk5M3oiIGZpbGw9IiM5NDk0OTQiLz48L3N2Zz4=) no-repeat center;