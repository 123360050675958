header .top-wrapper {
    .top-usp {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0.5rem 15px;

        li {
            margin-right: 2rem;
            color: $blue;
            font-family: $headings-font-family;
            font-weight: 700;

            a {
                display: flex;
                align-items: center;
                column-gap: 0.25rem;
                font-size: 0.9rem;
                text-decoration: none;

                span {
                    &.icon {
                        display: flex;
                        align-items: center;
                    }

                    [class*='star'] {
                        margin-top: -2px;
                        @include svg-icon(1rem, 1rem, $icon-star, $primary);
                    }
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.block-order-catalog {
    .follow {
        margin-top: 1rem;
        color: $gray-600;
    }
}
