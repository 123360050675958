.header-minicart,
.navbar-minicart {
    position: relative;

    .icon {
        @include svg-icon(1.5rem, 1.5rem, $icon-shopping-basket, $primary);
    }

    .text {
        display: none;
    }

    .counter {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 0;
        right: 0.25rem;
        background: $secondary;
        color: $white;
        font-size: 0.7rem;
        text-align: center;
        line-height: 1rem;
        border-radius: 50%;

        &.empty {
            display: none;
        }
    }
}

.navbar-minicart {
    .counter {
        right: 0rem;
    }
}


.offcanvas-minicart {
    font-family: $headings-font-family;

    .offcanvas-heading {
        .qty {
            display: none;
        }
    }

    .items-total,
    strong.subtitle:not(.empty) {
        display: none;
    }

    .subtotal,
    .subtitle {
        display: block;
        padding: 1rem 0;
    }

    .offcanvas-body {
        height: 100%;

        .minicart-items-wrapper {
            height: auto !important;
        }

        .product-item {
            .product-item-name a {
                color: $black;
            }

            .price-container {
                margin: 1rem 0;
                float: none !important;
            }

            .details-qty {
                display: none;
            }
        }
    }

    .offcanvas-footer {

        .btn,
        .action.primary {
            @include media-breakpoint-up($main-breakpoint) {
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
                font-weight: 700;
            }
        }

        .action.viewcart {
            @include button-outline-variant($secondary);
            border-width: 2px;
            background: $white;
        }

        .action.primary {
            border-width: 2px;

            &:hover {
                color: $white;
                background-color: $primary;
            }
        }
    }
}
