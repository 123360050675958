.catalog-product-view {
    .page-main {
        margin-bottom: 4rem;
    }

    .slick-arrow {
        background: $photo-slider-arrow-bg;
        color: $photo-slider-arrow-color;
        top: 40%;

        i {
            font-weight: 700;
        }

        &:before {
            @include svg-icon(1rem, 1rem, $icon-angle-left, $photo-slider-arrow-color);
        }

        span {
            display: none;
        }

        &.slick-next {
            &:before {
                @include svg-icon(1rem, 1rem, $icon-angle-right, $photo-slider-arrow-color);
            }
        }

        &.slick-prev {
            &:before {
                @include svg-icon(1rem, 1rem, $icon-angle-left, $photo-slider-arrow-color);
            }
        }
    }

    .related {
        .slick-arrow {
            top: 40%;
        }
    }

    .product-title-flex {
        align-items: center;
        font-family: $headings-font-family;

        .page-title {
            margin-bottom: 0;

            @include media-breakpoint-down($main-breakpoint) {
                margin-bottom: 2rem;
            }
        }

        .product-type-label,
        .product-type-link {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .product-studio {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .page-title {
        margin-bottom: 0;
        color: $primary;
    }

    .product-type-label {
        height: 2.5rem;
        width: 5rem;
        margin-left: 2rem;
        line-height: 2.5rem;
        text-align: center;
        color: $white;
        background-color: $primary;
        border-radius: 1rem;

        @include media-breakpoint-down($main-breakpoint) {
            position: absolute;
            margin-top: 1rem;
            margin-left: 1rem;
            z-index: 50;
        }
    }

    .product-photo-media {
        .product-type-label {
            @include media-breakpoint-up($main-breakpoint) {
                display: none;
            }
        }

        .product-type-link {
            display: none;
        }
    }

    .product-type-link {
        margin-left: 2rem;

        a {
            color: $gray-600;
            text-decoration: underline;
        }
    }

    .product-studio {
        margin-bottom: 1.5rem;
        color: $gray-600;

        a {
            color: $gray-600;
            text-decoration: underline;
        }
    }

    .product-details-wrapper {
        color: $gray-600;
        background-color: $white;
        font-family: $headings-font-family;

        .container {
            padding: 0;
        }

        .description-title {
            color: $primary;

            @include media-breakpoint-down($main-breakpoint) {
                margin-bottom: 2rem;
            }
        }

        .product-attribute.overview {
            margin-bottom: 1rem;
        }
    }
}

.product-main-info {
    font-family: $headings-font-family;

    .price-box {
        .price-wrapper {
            color: $primary;
            font-size: $h2-font-size;
            font-weight: 700;
        }

        .old-price {
            margin-right: 1rem;

            .price {
                text-decoration: line-through;
                color: $gray-400;
                font-weight: 400;
            }
        }

        .price-label {
            display: none;
        }
    }

    .tocart {
        height: 4rem;
        width: 14rem;
    }
}

.product-price-points {
    display: flex;
    justify-content: space-between;

    .product-icons {
        @include media-breakpoint-up($main-breakpoint) {
            display: none;
        }
    }
}

#downloadable-links-list .price-container {
    display: none;
}

.product-price-flex {
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    @include media-breakpoint-down($main-breakpoint) {
        display: block !important;
        padding-bottom: 1rem;
    }
}

.product-bottom-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-type-label {
        display: none;
    }

    .product-type-link {
        margin-left: 0;
        margin-right: 1rem;

        @include media-breakpoint-down($main-breakpoint) {
            margin-right: 0;
            margin-top: 2rem;
            margin-bottom: 1rem;
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: block;
    }
}

.product-icons {
    color: $primary;
    font-size: 2.5rem;

    i {
        margin-right: 0.5rem;
    }
}

.product-points {
    .btn+.btn {
        margin-left: 1rem;
    }
}

.page-product-downloadable {
    .product-add-form {
        width: 100%;

        form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            @include media-breakpoint-down($main-breakpoint) {
                flex-wrap: wrap;
            }
        }

        .product-options-wrapper {
            br {
                display: none;
            }
        }
    }

    .rewards__product-earn-points {
        display: none;
    }
}

.product-add-form {
    display: flex;
    align-items: center;

    .form-group.qty {
        display: none;
    }

    .action.towishlist {
        margin-left: 1rem;
        font-size: 2rem;

        .icon {
            @include svg-icon(2rem, 2rem, $icon-heart-outline, $primary);
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        flex-wrap: wrap;
    }

    .actions {
        display: flex;
        align-items: center;
    }

    #instant-purchase {
        display: none;
    }
}

.product-info-stock-sku {
    display: flex;
    align-items: center;
    max-width: 300px;

    .stock.available {
        margin-right: 1rem;
        font-weight: 700;
        text-transform: uppercase;

        &::before {
            @include svg-icon(1rem, 1rem, $icon-check, $green);
            vertical-align: middle;
        }
    }

    .stock-backorder {
        color: $danger;

        i {
            color: $danger;
        }
    }

    .sku.product-attribute {
        display: none;

        .label {
            font-weight: 400;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}

.product-mid-flex {
    margin: 1rem 0;

    @include media-breakpoint-down($main-breakpoint) {
        display: none !important;
        /* Bootstrap override */
    }
}

.product-main-info {
    .price-box {
        font-size: $h1-font-size;

        .rewards__product-earn-points {
            display: none;
        }
    }
}

.product-info-container {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        
        .product-main-info {
            order: 1;
        }
        
        .product-video-trailer {
            margin: map-get($spacers, 3) 0;
            order: 2;
        }
        
        .product-details-wrapper {
            order: 3;
        }
    }
}

.product-video-trailer {
    video {
        width: 100%;
        height: auto;
    }
}

.product-attribute {
    .label {
        font-weight: $font-weight-bold;
    }
}

.product-addto-links {
    margin-top: map-get($spacers, 3);
}

.attribute-collapse {
    .label {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h3-font-size);
    }

    .label-collapse {
        &:after {
            float: right;
            @include svg-icon(1rem, 1rem, $icon-minus, $primary);
        }

        &.collapsed {
            &:after {
                @include svg-icon(1rem, 1rem, $icon-plus, $primary);
            }
        }
    }
}

.additional-attributes {
    dl {
        @include make-col-ready();

        .item {
            @include make-row();

            +.item {
                margin-top: 1rem;
            }
        }

        dt {
            @include make-col(5);
        }

        dd {
            margin: 0;
            @include make-col(7);
            word-break: break-word !important;
        }
    }

    a {
        color: $gray-600;
        text-decoration: underline;
    }
}

.product-details-wrapper {
    padding: 2rem 0;
    margin-bottom: map-get($spacers, 4);
    background: $gray-100;
}

.block.related,
.block.upsell {
    .block-title {
        margin-bottom: 2rem;
        color: $primary;
        font-size: $h1-font-size;
    }
}

.fotorama:not(.fotorama--fullscreen) {
    .fotorama__stage {
        height: 500px !important;
    }
}

// .fotorama--fullscreen {
//     .fotorama__fullscreen-icon {
//         height: 40px;
//         width: 40px;
//         background: none !important;

//         &::after {
//             @include svg-icon(1rem, 1rem, $icon-times, $black);
//             font-family: $font-family-icon;
//             font-weight: 700;
//             vertical-align: middle;
//             border: none;
//             text-align: center;
//             line-height: 20px;
//         }
//     }
// }

@media(min-width: 768px) {
    .fotorama:not(.fotorama--fullscreen) {
        .fotorama__stage {
            height: 725px !important;

            .fotorama__stage__shaft {
                width: 100% !important;

                .fotorama__stage__frame {
                    img {
                        // position: static !important;
                        // display: block !important;
                        // transform: none !important;
                    }
                }
            }

            @media(max-width: 1200px) {
                height: 530px !important;
            }

            @media(max-width: 990px) {
                height: 400px !important;
            }
        }

        .fotorama__nav {
            height: auto !important;

            .fotorama__thumb-border {
                height: 171px !important;
                border-color: $primary !important;
            }

            .fotorama__nav__frame {
                height: 170px !important;

                .fotorama__thumb {
                    overflow: visible !important;
                }
            }
        }
    }
}
