.header-wishlist, .navbar-wishlist {
    .text {
        display: none;
    }
    .counter {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 0;
        right: 0.25rem;
        background: $secondary;
        color: $white;
        font-size: 0.7rem;
        text-align: center;
        line-height: 1rem;
        border-radius: 50%;

        &.empty {
            display: none;
        }
    }
}
.navbar-wishlist {
    .counter {
        right: 0rem;
    }
}
.products-grid.wishlist {
    .rewards__product-earn-points {
        display: none;
    }
}

