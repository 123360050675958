//
//  Common
//  ----------------------------------------------

.amcart-qty-wrap {
    & {
        display: none;
        // .lib-vendor-prefix-display();
        // display: flex;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        position: relative;
        margin: 0 map-get($spacers, 1) 0 map-get($spacers, 2);
    }

    >.amcart-label {
        position: absolute;
        top: 0;
        margin-right: map-get($spacers, 2);
        // .lib-css(line-height, @amcart-qty-control__height);
        line-height: $amcart-qty-control__height;
        font-weight: bold;
        // .lib-css(transform, translateY(-100%), 1);
        transform: translateY(-100%);
    }

    >.amcart-control {
        & {
            position: relative;
            width: 25px;
            // .lib-css(height, @amcart-qty-control__height);
            height: $amcart-qty-control__height;
            // .lib-css(background, @amcart-qty-control__background);
            background: $amcart-qty-control__background;
            cursor: pointer;
            // .lib-css(transition, all .3s ease-in-out);
            transition: all .3s ease-in-out;
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: $font-size-base;
            // .lib-css(line-height, @amcart-qty-control__height);
            line-height: $amcart-qty-control__height;
            text-align: center;
            color: $white;
        }

        &:hover {
            // .lib-css(background, darken(@amcart-qty-control__background, 10%));
            background-color: darken($amcart-qty-control__background, 10);
        }
    }

    >.amcart-control.-minus {
        & {
            order: 1;
        }

        &:before {
            content: '-';
        }
    }

    >.amcart-control.-plus {
        & {
            order: 3;
        }

        &:before {
            content: '+';
        }
    }

    >.amcart-input,
    >.amcart-input.input-text.qty {
        margin: 0 map-get($spacers, 1);
        padding: map-get($spacers, 1);
        width: 40px;
        // .lib-css(height, @amcart-qty-control__height);
        height: $amcart-qty-control__height;
        box-sizing: border-box;
        text-align: center;
        order: 2;
    }

    >.amcart-refresh {
        & {
            margin-left: map-get($spacers, 2);
            width: 1.25rem;
            // .lib-css(height, @amcart-qty-control__height);
            height: $amcart-qty-control__height;
            // .lib-css(background, @amcart-qty-refresh__background);
            background: $amcart-qty-refresh__background;
            order: 4;
            cursor: pointer;
            opacity: .7;
            visibility: hidden;
            // .lib-css(transition, all .3s ease-in-out);
            transition: all .3s ease-in-out;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.amcart-message-box>.amcart-qty-wrap {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: map-get($spacers, 1);
}


//
//  Tablet +
//  --------------------------------------------

// .media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__m) {
@include media-breakpoint-down($main-breakpoint) {
    .amcart-qty-wrap {
        & {
            margin: 0 0 0 25px;
        }

        >.amcart-label {
            position: static;
            // // .lib-css(transform, none, 1);
        }

        >.amcart-refresh {
            margin-left: 15px;
        }
    }

}
