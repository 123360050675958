.block-search {
    .input-group {
        box-shadow: $input-box-shadow;
    }

    .form-control {
        box-shadow: none;
    }

    ::placeholder {
        color: $gray-500;
    }

    .actions {
        align-items: center;
        padding: 0.375rem 0.75rem;

        .action.search {
            border: $white;
            background: $white;
            @include svg-icon(1rem, 1rem, $icon-search, $primary);
            
            &::before {
                display: none;
            }
        }
    }
}

.navbar-search {
    display: inline-block;
    vertical-align: middle;
    margin-left: map-get($spacers, 2);

    .icon {
        @include svg-icon(1.4rem, 1.4rem, $icon-search, $primary);
    }

    &.show {
        .navbar-search {
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        }
    }

    .navbar-search {
        display: block;
        width: 100%;
        position: absolute;
        top: 4.5rem;
        left: 0;
        padding: 1rem 0;
        background: $white;
        transform: translateY(1rem);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s ease, transform 0.5s ease;
        z-index: 100;

        .form-group {
            width: 100%;
        }
    }

}


$spinner-color-from: #D8D8D8;
$spinner-color-to: #D8D8D8;

$mute-color: $gray-700;
$text-color: #333;

.mst-searchautocomplete__autocomplete {
    display: none !important;
    position: absolute;
    top: 3.1rem;
    left: 0;
    box-sizing: border-box;
    background: $white;
    border-radius: 0 0 1px 1px;
    min-height: 3.5rem;
    z-index: 1300;
    text-align: left;
    margin-left: 0;

    &._active {
        display: block !important;
    }

    .mst-searchautocomplete__spinner {
        display: none;
        position: absolute;
        top: 1rem;
        left: 50%;
        margin-left: -90px;
    }

    &._loading {
        .mst-searchautocomplete__spinner {
            display: block;
        }
    }

    .mst-searchautocomplete__empty-result {
        background: $white;
        text-align: center;
        padding: 8px 10px;
        color: $mute-color;
        font-size: 1.2rem;
        display: block;
    }

    .mst-searchautocomplete__show-all {
        background: $gray-300;
        text-align: center;

        a {
            padding: 8px 10px;
            color: $mute-color;
            font-size: $font-size-sm;
            display: block;
        }
    }

    .mst-searchautocomplete__index-title {
        background: $primary;
        padding: 8px 10px;
        text-align: left;
        font-size: $font-size-sm;
        line-height: 1.2rem;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
    }

    .mst-searchautocomplete__close {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0 1rem;
            padding: 0.25rem 0;
            cursor: pointer;

            a.title {
                font-weight: 600;
                margin-bottom: 0;
                color: $text-color;
                text-decoration: none;

                i {
                    padding: 0 0.5rem;
                    font-style: normal;
                }
            }

            &._active {
                a.title {
                    text-decoration: underline;
                }
            }
        }
    }
}

.mst-searchautocomplete__index {
    &.popular {
        .index-title {
            background: none;
            text-align: left;
            color: $gray-900;
        }

        ul {
            li {
                float: left;
                padding: .7rem 0 .7rem 1rem;
                margin: 0;

                a {
                    font-size: $font-size-sm;
                    color: $gray-700;
                }

                &:hover {
                    background: none;
                    cursor: pointer;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.mst-searchautocomplete__item {
    &.magento_catalog_product {
        display: flex;
        border-bottom: 1px solid $gray-300;
        align-items: center;

        &:last-child {
            border-bottom: none;
        }

        img {
            height: 6rem;
            max-width: 6rem;
            margin-right: 1rem;
        }

        .title {
            display: block;
            margin-bottom: 0.25rem;

            a {
                color: $text-color;
            }

            .sku {
                color: $mute-color;
            }
        }

        .meta {
            flex-grow: 1;
            overflow: hidden;
        }

        .description {
            font-size: $font-size-sm;
            color: $mute-color;
            height: 3rem;
            line-height: 1.5rem;
            overflow: hidden;
            margin-bottom: 0.25rem;
        }

        .price {
            display: block;
            font-weight: 600;
            margin-left: 1rem;
            white-space: nowrap;
            font-size: 0.95rem;

            .price-label {
                display: none;
            }
        }

        .special-price {
            .price {
                margin-top: 1rem;
                color: $red;
            }
        }

        .old-price {
            .price {
                text-decoration: line-through;
                color: #c9c9c9;
            }
        }

        .product-reviews-summary {
            margin: 0;

            .reviews-actions {
                display: none;
            }
        }

        .to-cart {
            float: right;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        &._active {
            background: $white !important;

            .title {
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    &.magento_catalog_category {
        a {
            i {
                color: $mute-color;
                display: inline-block;
                margin: 0 .5rem;
                font-style: normal;
            }
        }
    }

    &.magento_search_query {
        a {
            float: left;
        }

        .num_results {
            float: right;
            color: $mute-color;
        }
    }
}

.mst-searchautocomplete__highlight {
    font-weight: 600;
    color: $secondary;
}

.mst-searchautocomplete__spinner {
    position: relative;
    width: 186px;
    height: 20px;
    margin: auto;

    .spinner-item {
        position: absolute;
        top: 0;
        background-color: $spinner-color-from;
        width: 20px;
        height: 20px;
        animation-name: bounce_fountainG;
        animation-duration: 0.645s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        transform: scale(.3);
        border-radius: 15px;

        &.spinner-item-1 {
            left: 0;
            animation-delay: 0.256s;
        }

        &.spinner-item-2 {
            left: 20px;
            animation-delay: 0.3225s;
        }

        &.spinner-item-3 {
            left: 40px;
            animation-delay: 0.389s;
        }

        &.spinner-item-4 {
            left: 60px;
            animation-delay: 0.4555s;
        }

        &.spinner-item-5 {
            left: 80px;
            animation-delay: 0.522s;
        }

        &.spinner-item-6 {
            left: 100px;
            animation-delay: 0.5885s;
        }

        &.spinner-item-7 {
            left: 120px;
            animation-delay: 0.645s;
        }

        &.spinner-item-8 {
            left: 140px;
            animation-delay: 0.7115s;
        }
    }
}

@keyframes bounce_fountainG {
    0% {
        transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-o-keyframes bounce_fountainG {
    0% {
        -o-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -o-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-ms-keyframes bounce_fountainG {
    0% {
        -ms-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -ms-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -webkit-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}

@-moz-keyframes bounce_fountainG {
    0% {
        -moz-transform: scale(1);
        background-color: $spinner-color-from;
    }

    100% {
        -moz-transform: scale(.3);
        background-color: $spinner-color-to;
    }
}


//
//    Desktop
//--------------------------------------
@include media-breakpoint-up($main-breakpoint) {
    .mst-searchautocomplete__autocomplete {
        width: 100%;

        &._active {
            display: block !important;

            &.mst-2-cols {
                width: 720px !important;
                position: absolute;

                .mst-searchautocomplete__index {
                    clear: left;
                    float: left;
                    display: block;
                    position: relative;
                    max-width: 240px;
                    width: 240px;
                    padding-bottom: 10px;
                    box-sizing: border-box;
                    border-right: 1px solid $gray-300;
                    margin-right: -1px;
                }

                div.magento_catalog_product {
                    width: 480px !important;
                    max-width: 480px !important;
                    clear: right;
                    float: right;
                    border-left: 1px solid $gray-300;
                    border-right: unset;
                    margin-left: -1px;
                }

                .mst-searchautocomplete__show-all {
                    clear: both;
                }
            }
        }
    }
}

//
//    Mobile
//--------------------------------------
@include media-breakpoint-down($main-breakpoint) {
    .mst-searchautocomplete__autocomplete {
        .mst-searchautocomplete__close {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            font-weight: bold;
            color: $gray-900;
            font-size: 1.6rem;
            padding: 1rem;
            line-height: 1rem;
            cursor: pointer;
        }
    }
}

.catalogsearch-result-index {
    .message.notice {
        background-color: initial;
        border: none;
    }
}
