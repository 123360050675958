.navbar {
    padding: 0;

    .navbar-header {
        @include media-breakpoint-down(lg) {
            .navbar-header-center {
                width: 40%;
            }

            .navbar-header-left {
                width: 30%;
            }
            .navbar-header-right {
                width: 30%;
            }
        }

        @include media-breakpoint-down(sm) {
            .navbar-header-right {
                text-align: right;

                .btn {
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;

                    .icon:before {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }

    .col-md-9 {
        @include media-breakpoint-down(lg) {
            padding: 0;
            max-width: 100%;
        }
    }

    .navbar-nav {
        .dropdown:last-of-type {
            position: relative;

            .dropdown-menu {
                left: 0;
            }
        }
    }

    .nav-item {
        padding: 1rem 1rem;
        list-style-type: none;
        transition: background-color 0.3s ease;

        @include media-breakpoint-up($main-breakpoint) {
            position: static;
        }

        &:hover {
            background-color: $white;

            .dropdown-menu {
                margin: 0;

                @include media-breakpoint-up($main-breakpoint) {
                    right: 0;
                }

                @include media-breakpoint-down($main-breakpoint) {
                    position: static;
                    padding: 1rem 0;
                    box-shadow: none;

                    .row {
                        flex-wrap: wrap !important;
                    }
                }
            }
        }

        .level-2 {
            min-width: 15rem;
        }

        a.nav-link {
            color: $primary;
            font-family: $headings-font-family;
            font-weight: 700;
            text-transform: uppercase;
        }

        + .nav-item {
            @include media-breakpoint-up(lg) {
                border-left: 1px solid $gray-400;
            }
        }
    }
}

.top-menu__mobile {
    display: none;
}

.navbar-icons {
    text-align: right;

    .btn {
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;

    img {
        height: 100%;
        max-height: 1.5rem;
    }
}

.navbar.bg-light {
    @include media-breakpoint-down($main-breakpoint) {
        background-color: $white !important;
    }
}

.navbar-header {
    padding: 1rem 15px;
    border-bottom: 1px solid $gray-200;
}

@include media-breakpoint-up(lg) {
    #navbar-offcanvas {
        position: relative;

        .col-md-9 {
            position: static;
        }
    }
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-nav .show > .nav-link {
    color: $primary;
}

$iconClasses: (
    toggler: $icon-bars,
    search: $icon-search,
    account: $icon-user,
    minicart: $icon-shopping-basket,
    wishlist: $icon-heart,
);
$iconDesktopSize: 1.4rem;
$iconMobileSize: 1.25rem;

@each $class, $icon in $iconClasses {
    .header,
    .navbar {
        &-#{$class} {
            .icon {
                @include svg-icon($iconDesktopSize, $iconDesktopSize, $icon, $primary);
                vertical-align: middle;

                @include media-breakpoint-down($main-breakpoint) {
                    @include svg-icon($iconMobileSize, $iconMobileSize, $icon, $primary);
                }
            }
        }
    }
}
